import React, { useState } from 'react';
import { styled } from '@mui/joy/styles';
import { motion } from 'framer-motion';
import PuzzleBoard from '../Composants/PuzzleBoard';
import { Card, CardHeader, CardBody, Modal, ModalContent, ModalHeader, ModalBody, Button, Image, ModalFooter } from "@nextui-org/react";

import image from '../assets/images/Epargne_BPBF.png';

import puzzleback from '../assets/puzzlegame.png'
import mobilegameback from '../assets/mobilegameback.png'

// Importez d'autres composants au besoin


const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  overflow: 'hidden',
  padding: '20px',
  '@media (max-width: 600px)': {
    padding: '10px',
  },
});

const Header = styled('h1')({
  fontSize: '2.5rem',
  color: '#333',
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
  fontFamily: 'Comic Sans MS', // Add a fun font family
  textShadow: '2px 2px 4px #000000', // Add a text shadow for a playful effect
});



const GameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});

const ReferenceImageContainer = styled('div')({
  width: '200px',
  height: '200px',
  borderRadius: '10%',
  overflow: 'hidden',
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    width: '150px',
    height: '150px',
    marginBottom: '10px',
  },
});

const ReferenceImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

 // URL de l'image de fond pour les appareils de bureau
 const desktopBackground = puzzleback;

 // URL de l'image de fond pour les appareils mobiles
 const mobileBackground = mobilegameback;

 // Fonction pour déterminer si l'appareil est un mobile
 const isMobile = () => {
   return window.innerWidth <= 600; // ou une autre largeur en fonction de vos besoins
 };

 const backgroundStyle = {
   backgroundImage: `url('${isMobile() ? mobileBackground : desktopBackground}')`,
   backgroundPositionX: "center",
   backgroundPositionY: "bottom",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
   width: "100%",
   height: "100vh",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
 };

const GamePage = () => {

  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div 
    className="min-h-screen flex items-center justify-center"
    style={backgroundStyle}
    >
      <style>
        {`
          .custom-card {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
          }
          .centered-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .card-footer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap; /* Permet aux boutons de passer à la ligne sur les petits écrans */
          }
          .card-footer .button {
            flex: 1; /* Les boutons se répartissent également sur la largeur de l'écran */
            margin: 5px; /* Marge entre les boutons */
            max-width: 200px; /* Largeur maximale pour éviter le débordement sur les petits écrans */
          }
          
          /* Ajouter une classe pour gérer la largeur de la carte sur les petits écrans */
          @media screen and (max-width: 600px) {
            .custom-card {
              width: 100%;
              
            }
          }
        `}
      </style>
    <PageContainer
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Header>Tentez votre chance</Header>

     
      
      <GameContainer>
        <PuzzleBoard />
        <ReferenceImageContainer>
          <ReferenceImage src={image} alt="Image de référence" />
        </ReferenceImageContainer>
      </GameContainer>
      {/* Vous pouvez ajouter plus d'éléments ici si nécessaire */}
    </PageContainer>
    <Modal backdrop="opaque"
        isOpen={isModalOpen}
        onClose={closeModal}
        classNames={{
          //body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          //base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          //header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-black/5 active:bg-black/10",
        }}
      >
        <ModalContent>
          <ModalHeader>
            Comment Jouer
          </ModalHeader>

          <ModalBody>
            {/* Ajoutez ici votre image */}
            <Image src="https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fhelp.png?alt=media&token=632de4a6-53cc-44b5-b3b8-00e338457edc" alt="Tuto" width={400} height={300} />
          </ModalBody>


          <p
            //ajoute un style pour centrer
            className="text-center text-sm text-gray-500 dark:text-gray-400 py-2"
          >
            Déplacez les pièces du puzzle pour reconstituer l'image
          </p>
          <Button className="bg-[#048337] shadow-lg shadow-indigo-500/20" onClick={closeModal} style={{ backgroundColor: "#048337", color: "white" }} >
            Fermer
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
};

export default GamePage;
