import React, { useState, useEffect } from 'react';
import { styled } from '@mui/joy/styles';
import { motion } from 'framer-motion';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const TimerContainer = styled(motion.div)(({ time }) => ({
  padding: '10px 20px',
  margin: '10px',
  borderRadius: '10px',
  backgroundColor: time <= 10 ? 'red' : time <= 30 ? 'orange' : 'green',
  color: '#333',
  display: 'inline-block',
  fontSize: '20px',
  fontWeight: 'bold',
  userSelect: 'none',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
}));

const formatTime = (time) => {
  const getSeconds = `0${(time % 60)}`.slice(-2);
  const minutes = `${Math.floor(time / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}:${getSeconds}`;
};

const Timer = ({ isPuzzleSolved, setIsPuzzleSolved }) => {
  const auth = getAuth();

const handleQuit = async () => {
  try {
    // Déconnectez l'utilisateur anonyme
    await signOut(auth);
    navigate('/win');
  } catch (error) {
    console.error("Erreur lors de la déconnexion : ", error);
  }
};
  const [secondsElapsed, setSecondsElapsed] = useState(60);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleRetry = () => {
    // Réinitialisez le temps
    setSecondsElapsed(60);
    // Fermez le modal
    setOpenModal(false);
    // Réinitialisez le statut de résolution du puzzle
    setIsPuzzleSolved(false);
    
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsElapsed((seconds) => seconds > 0 ? seconds - 1 : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (secondsElapsed === 0 && !isPuzzleSolved) {
      setOpenModal(true);
    }
  }, [secondsElapsed, isPuzzleSolved]);



  return (
    <>
      <TimerContainer
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring', stiffness: 100 }}
        //color={getColor()}
        time={secondsElapsed}
      >
        {formatTime(secondsElapsed)}
      </TimerContainer>
      <Modal
        backdrop="opaque"
        isOpen={openModal}
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
                <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Réessayer?</ModalHeader>
              <ModalBody>
                <p>Tentez votre chance de nouveau </p>
                
              </ModalBody>
              <ModalFooter>


              {/* <Link to ="/game"> */}
          <Button
            className="bg-[#F9FAFA] shadow-lg shadow-green-500/20"
            onPress={handleRetry}
          >
            Réessayer
          </Button>
          {/* </Link> */}
          
          
          <Button color="foreground" variant="light" onPress={handleQuit}>
            Quitter
          </Button>
          
          
          </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Timer;
