
import React, { useEffect, useState, useRef } from 'react';

import { styled } from '@mui/joy/styles';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter,} from "@nextui-org/react";
import confetti from "canvas-confetti";
import Question from "./Question";

import GameController from '../Controller/GameController';
import PuzzlePiece from './PuzzlePiece';
import Timer from './Timer';


// Exemple d'importation des images et des masques
import imagePart1 from '../assets/images/piece_1.png';
import imagePart2 from '../assets/images/piece_2.png';
import imagePart3 from '../assets/images/piece_3.png';
import imagePart4 from '../assets/images/piece_4.png';
import imagePart5 from '../assets/images/piece_5.png';
import imagePart6 from '../assets/images/piece_6.png';
import imagePart7 from '../assets/images/piece_7.png';
import imagePart8 from '../assets/images/piece_8.png';
import imagePart9 from '../assets/images/piece_9.png';

import maskPart1 from '../assets/mask/mask1.svg';
import maskPart2 from '../assets/mask/mask2.svg';
import maskPart3 from '../assets/mask/mask3.svg';
import maskPart4 from '../assets/mask/mask4.svg';
import maskPart5 from '../assets/mask/mask5.svg';
import maskPart6 from '../assets/mask/mask6.svg';
import maskPart7 from '../assets/mask/mask7.svg';
import maskPart8 from '../assets/mask/mask8.svg';
import maskPart9 from '../assets/mask/mask9.svg';


const imagesArray = [imagePart1, imagePart2,imagePart3, imagePart4, imagePart5, imagePart6, imagePart7, imagePart8, imagePart9];
const masksArray = [maskPart1, maskPart2, maskPart3, maskPart4, maskPart5, maskPart6, maskPart7, maskPart8, maskPart9];
const winningPositions = [
  {id: 1, x: 1, y: 1}, {id: 2, x: 2, y: 1}, {id: 3, x: 3, y: 1},
  {id: 4, x: 1, y: 2}, {id: 5, x: 2, y: 2}, {id: 6, x: 3, y: 2},
  {id: 7, x: 1, y: 3}, {id: 8, x: 2, y: 3}, {id: 9, x: 3, y: 3}
];

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const minDimension = Math.min(screenWidth, screenHeight); // Utiliser la plus petite dimension de l'écran
  const gridSizeFactor = 4; // Un facteur pour ajuster la taille de la grille en fonction de l'écran
  const newGridSize = Math.floor(minDimension / gridSizeFactor); // Calculer la taille de la grille

const Board = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 150px)',
  gridTemplateRows: 'repeat(3, 150px)',
  gap: '0px',
  position: 'relative',
  width: '450px',
  height: '450px',
  margin: 'auto',
  border: '1px solid black',
  '@media (max-width: 600px)': {
    gridTemplateColumns: `repeat(3, ${newGridSize}px)`,
    gridTemplateRows: `repeat(3, ${newGridSize}px)`,
    width: `${newGridSize * 3}px`,
    height: `${newGridSize * 3}px`,
  },

});


const PuzzleBoard = ({pieces}) => {
  const combinedArray = imagesArray.map((image, index) => ({
    id: index + 1, // ID de la pièce (en commençant par 1)
    image: image, // Image de la pièce
    mask: masksArray[index] // Masque correspondant à l'image
  }));
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [gameController] = useState(new GameController(3, combinedArray));
  const [board, setBoard] = useState([]);
  const [isPuzzleSolved, setIsPuzzleSolved] = useState(false);
  const gridRef = useRef(null); // Création du ref
  const [gridSize, setGridSize] = useState(150); // Définir la taille initiale de la grille



  
  useEffect(() => {
    const updateGridSize = () => {
      const newGridSize = window.innerWidth <= 600 ? 100 : 150; // Définir la taille de la grille pour les écrans mobiles
      setGridSize(newGridSize);
    };

    window.addEventListener('resize', updateGridSize);
    updateGridSize(); // Appeler immédiatement pour définir la taille initiale

    return () => window.removeEventListener('resize', updateGridSize);
  }, []);
  
  useEffect(() => {
    const initialBoard = gameController.board;
    setBoard(initialBoard);
    //console.log(screenWidth,screenHeight,minDimension);
    
  }, [gameController]);


  const onDragEnd = (pieceId, newX, newY) => {
    // Limitez les nouvelles positions à la taille de la grille
    const validX = Math.max(1, Math.min(newX, 3));
    const validY = Math.max(1, Math.min(newY, 3));
  
    // Mettre à jour la position de la pièce et récupérer le tableau mis à jour
    const updatedBoard = gameController.onMove(pieceId, validX, validY);
    
    // Mettre à jour l'état board avec le nouveau tableau
    setBoard(updatedBoard);
    
    //console.log("Positions après le déplacement :", updatedBoard.map(p => ({ id: p.id, x: p.x, y: p.y })));
  
    // Vérifier si le puzzle est résolu
    const isSolved = winningPositions.every(pos => {
      const piece = updatedBoard.find(p => p.id === pos.id);
      return piece && piece.x === pos.x && piece.y === pos.y;
    });
  
    if (isSolved) {
      setIsPuzzleSolved(true);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
      setIsPopupOpen(true);
      console.log('Puzzle résolu!');
    }
  };
  

  
  



  return (
    <>
     <Timer isPuzzleSolved={isPuzzleSolved} setIsPuzzleSolved={setIsPuzzleSolved} />
    <Board  ref={gridRef} style={{ gridTemplateColumns: `repeat(3, ${gridSize}px)`, gridTemplateRows: `repeat(3, ${gridSize}px)` }} >
      {board.map(piece => (
        <PuzzlePiece
          key={piece.id}
          id={piece.id}
          image={imagesArray[piece.id - 1]}
          //mask={masksArray[piece.id - 1]}
          position={{ x: piece.x, y: piece.y }}
          //initialPosition={{ x: piece.initialX, y: piece.initialY }}
          gridSize={gridSize} // Passer gridSize à PuzzlePiece
          gridRef={gridRef} // Passer le ref à chaque PuzzlePiece
          onDragEnd={onDragEnd}
        />
      ))}
    </Board>
      <Modal
        backdrop="opaque"
        isOpen={isPopupOpen}
        onOpenChange={() => setIsPopupOpen(false)} // Fermer le popup en changeant l'état
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Félicitation!</ModalHeader>
              <ModalBody>
                <p>Vous avez resolut le puzzle,vous êtes éligible pour le tirage au sort  </p>
                
              </ModalBody>
              <ModalFooter>

                <Question />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

    </>
  );
};

export default PuzzleBoard;