import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './View/HomePage';
import GamePage from './View/GamePage';
import FormPage from './View/FormPage';
import Win from './View/WinPage';

// Importez d'autres composants et vues au besoi
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/game" element={<GamePage />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/win" element={<Win />} />
        
        {/* Définissez d'autres routes au besoin */}
      </Routes>
    </Router>
  );
}

export default App;
