import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/joy/styles';

const StyledPuzzlePiece = styled(motion.div)(({ image,size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  
  
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  //maskImage: `url(${mask})`,
  maskSize: 'cover',
  maskRepeat: 'no-repeat',
  cursor: 'grab', // Ajout du curseur "grab" pour indiquer que l'élément est déplaçable
}));

/**
 * Composant représentant une pièce de puzzle.
 * 
 * @param {Object} props - Les propriétés du composant.
 * @param {string} props.id - L'identifiant de la pièce.
 * @param {string} props.image - L'image de la pièce.
 * @param {string} props.mask - Le masque de la pièce.
 * @param {function} props.onDragEnd - La fonction de rappel appelée lorsque le glissement se termine.
 * @param {Object} props.position - La position actuelle de la pièce sur la grille.
 * @param {Object} props.initialPosition - La position initiale de la pièce sur la grille.
 * @param {Object} props.gridRef - La référence à la grille.
 * @returns {JSX.Element} - Le composant de la pièce de puzzle.
 */
export default function PuzzlePiece({ id, image, mask, onDragEnd, position, initialPosition, gridRef, }) {
  //const gridSize = 150;
  const [gridSize, setGridSize] = useState(150);
  

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const minDimension = Math.min(screenWidth, screenHeight); // Utiliser la plus petite dimension de l'écran
  const gridSizeFactor = 4; // Un facteur pour ajuster la taille de la grille en fonction de l'écran
  let newGridSize = Math.floor(minDimension / gridSizeFactor); // Calculer la taille de la grille
  if (newGridSize > 150 ) {
    newGridSize = 150;
  }
  
      setGridSize(newGridSize);
      
     
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    //console.log("gridsize :", gridSize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, );
  


  

  // Calcule la position absolue basée sur les coordonnées de la grille
  const calculatePosition = (gridCoordinate) => {
    return (gridCoordinate - 1) * gridSize;
  };

  return (
    <StyledPuzzlePiece
      image={image}
      //mask={mask}
      size={gridSize}
      style={{
        width: `${gridSize}px`, // Utiliser gridSize pour la largeur
        height: `${gridSize}px`, // Utiliser gridSize pour la hauteur
        
        position: 'absolute',
        top: calculatePosition(position.y),
        left: calculatePosition(position.x),
      }}
      drag
      dragMomentum={false} // Désactive l'élan de glissement
      dragElastic={1} // Désactive l'élasticité de glissement
      onDragStart={(event, info) => {
        // console.log((info.point.x / gridSize), (info.point.y / gridSize));
        // console.log("Grid", gridSize);
        // console.log(`Drag Start - Piece ID: ${id}, Initial Position: X: ${position.x}, Y: ${position.y}`);
      }}
      onDragEnd={(event, info) => {
        if (gridRef.current) {
          const gridOffset = gridRef.current.getBoundingClientRect();
          const relativeX = info.point.x - gridOffset.left;
          const relativeY = info.point.y - gridOffset.top;
          const gridX = Math.ceil(relativeX / gridSize);
          const gridY = Math.ceil(relativeY / gridSize);
          const validX = Math.max(1, Math.min(gridX, 3));
          const validY = Math.max(1, Math.min(gridY, 3));
          // console.log((info.point.x / gridSize), (info.point.y / gridSize));
          // console.log(`Drag End - Piece ID: ${id}, New Position: X: ${validX}, Y: ${validY}`);
          onDragEnd(id, validX, validY);
        }
      }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    />
  );
}
