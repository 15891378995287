import React from "react";
import { Card, CardHeader, CardBody } from "@mui/joy/";
import Forme from "../Composants/form";
//import { FaGift, } from "react-icons/fa";

export default function Form() {
 


  return (
    <div style= {{justifyContent : "center", alignItems : "center", display: "flex"}}>
    <Card className="py-4" style={{ width: '700px', }}>
        <style>
        {`
        .centered-content {
            display: flex;
            justify-content: center; /* Centre horizontalement */
            align-items: center; /* Centre verticalement */
            flex-direction: column; /* Pour centrer le contenu verticalement */
            height: 100%; /* Remplir la hauteur de la carte */
          }
          
          .custom-card {
            width: 450px;
            /* Ajoutez d'autres styles personnalisés ici */
          }
        `}

        </style>
        {/* <CardHeader className="pb-0 pt-2 px-4 flex-col items-start centered-content">
  <div className="flex items-center mb-1">
    <FaGift className="text-2xl text-yellow-500 mr-1" />
    <p className="text-tiny uppercase font-bold text-yellow-500">Concours</p>
  </div>
  
  
</CardHeader> */}
      {/* <CardBody className="overflow-visible py-2 centered-content">
        <Image
          alt="Card background"
          className="object-cover rounded-xl"
          src="https://scontent.foua1-1.fna.fbcdn.net/v/t39.30808-6/337411579_887657282487713_2090060858766128324_n.jpg?_nc_cat=1&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeHcumfKlA3DLllXFeTjK6loIsDFr-EpiBwiwMWv4SmIHLpNlaZrv143F8CRK89W9SXT_u_LHu46prUZqqn4YQWp&_nc_ohc=Sl67Zuxu-PEAX-WiPbD&_nc_zt=23&_nc_ht=scontent.foua1-1.fna&oh=00_AfDna_QW7NxZ_NX7fna5w2ZCA_7OtExvs1nZfvVPM-tuFA&oe=64EA0583"
          width={350}
        /> 
        {/* <Form/> 
        
          
        {/* <But/> 
        {/* <Img/> 
        
        <Forme/>
      </CardBody>  */}
      <Forme/>
    </Card>
    </div>
  );
}