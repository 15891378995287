export default class GameController {
  constructor(size, combinedArray) {
    this.size = size;
    this.combinedArray = combinedArray; // Stocke le tableau combiné
    
    this.board = this.initializeBoard();
    
    
  }

  initializeBoard() {
    const shuffledArray = this.shuffleArray(this.combinedArray);
    const occupiedPositions = new Set();

    return shuffledArray.map(piece => {
      let position;
      do {
        position = this.getUniqueRandomPosition(occupiedPositions);
      } while (occupiedPositions.has(`${position.x},${position.y}`));

      occupiedPositions.add(`${position.x},${position.y}`);

      return {
        id: piece.id,
        x: position.x,
        y: position.y,
        image: piece.image,
        mask: piece.mask
      };
    });
  }

  getUniqueRandomPosition(occupiedPositions) {
    let x, y;
    do {
      x = this.getRandomGridPosition();
      y = this.getRandomGridPosition();
    } while (occupiedPositions.has(`${x},${y}`));
    return { x, y };
  }

  shuffleArray(array) {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    
    return shuffled;
  }

  getRandomGridPosition() {
    // Générez une position aléatoire sur la grille
    return Math.ceil(Math.random() * this.size);
  }

 /**
   * Met à jour la position d'une pièce du puzzle.
   * @param {number} pieceId - L'identifiant de la pièce à déplacer.
   * @param {number} newX - La nouvelle position X de la pièce.
   * @param {number} newY - La nouvelle position Y de la pièce.
   */
 onMove(pieceId, newX, newY) {
  // Trouver la pièce dans le tableau en utilisant son identifiant.
  const piece = this.findPieceById(pieceId);

  // Si la pièce est trouvée, mettre à jour ses positions X et Y.
  if (piece) {
    piece.x = newX;
    piece.y = newY;
  }
  //console.log(this.board);
  // Retourner le tableau mis à jour
  return this.board;
  
}






/**
   * Trouve une pièce du puzzle par son identifiant.
   * @param {number} pieceId - L'identifiant de la pièce à trouver.
   * @returns {Object|null} - La pièce trouvée, ou null si elle n'existe pas.
   */
findPieceById(pieceId) {
  // Recherche la pièce dans le tableau board en utilisant son identifiant.
  return this.board.find(piece => piece.id === pieceId) || null;
}

}