import React from 'react';
import { styled } from '@mui/joy/styles';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/joy/Button';

import GlobeImg from '../assets/images/homepuzzle.png';
import homeback from '../assets/puzzlehome.png';
import mobilehomeback from '../assets/mobilegamenew.png'


const PageContainer = styled(motion.div)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align to the left to place the text over the image
  justifyContent: 'center',
  height: '100vh',
  position: 'relative', // For absolute positioning of the SVG
  overflow: 'hidden', // To hide the overflowing SVG
  
});



const containerVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { delay: 0.3, duration: 0.5 } }
};







const ImageContainer = styled('div')({
  alignSelf: 'center', // Alignement au centre sur l'axe transversal
  width: '30vw',
  height: '30vw',
  overflow: 'hidden',
  borderRadius: '20px',
  position: 'relative',
  margin: 'auto', // Centrage horizontal
  zIndex: 1,
  '@media (max-width: 600px)': {
    width: '80vw',
    height: '80vw',
    top: '40px', // Ajustement de la position
    paddingRight: '0', // Assurez-vous de retirer le paddingRight pour les écrans mobiles
  },
});




const GlobeImage = styled('img')({
  display: 'block',
  width: '100%', // Use 100% of the container width
  height: '100%', // Use 100% of the container height to maintain the aspect ratio
  objectFit: 'cover', // Cover the container with the image without stretching it
  userDrag: 'none',
  borderRadius: '20px', // Adjust the px value to increase or decrease the roundness of the corners
});




const StyledButton = styled(Button)({
  // ... existing styles,
  position: 'absolute',
  bottom: '1rem', // Adjust as necessary
  left: '50%',
  display: 'flex',
  transform: 'translateX(-50%)',
  zIndex: 3, // Above all other elements
  backgroundColor: '#F9D71C', // Use the theme color or any color you prefer
  color: 'white',
  padding: '1rem 2rem',
  fontSize: '1.25rem',
  borderRadius: '30px', // Rounded corners
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    padding: '0.75rem 1.5rem', // Plus petit sur les écrans mobiles
    fontSize: '1.5rem', // Taille de police plus petite
    bottom: '0.5rem', // Ajustement de la position
    left: '50%',
    top: '80%',
    height: '80px',
    width: '100px',
    transform: 'translateX(-50%)',
  },
  // Add a smooth transition for hover and tap effects
  transition: 'transform 0.1s ease-in-out',
  '&:hover': {
    backgroundColor: '#0D5698', // Slightly darker shade for the hover state
    // Override the scale from buttonVariants.hover if necessary
  }
  
});



 // URL de l'image de fond pour les appareils de bureau
 const desktopBackground = homeback;

 // URL de l'image de fond pour les appareils mobiles
 const mobileBackground = mobilehomeback;

 // Fonction pour déterminer si l'appareil est un mobile
 const isMobile = () => {
   return window.innerWidth <= 600; // ou une autre largeur en fonction de vos besoins
 };

 const backgroundStyle = {
   backgroundImage: `url('${isMobile() ? mobileBackground : desktopBackground}')`,
   backgroundPositionX: "center",
   backgroundPositionY: "bottom",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
   width: "100%",
   height: "100vh",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
 };


const HomePage = () => {
  const navigate = useNavigate();

  const handleStartGame = () => {
    navigate('/form'); // Replace with the actual game path
  };

  return (
    <div 
    className="min-h-screen flex items-center justify-center"
    style={backgroundStyle}
    >
      <style>
        {`
          .custom-card {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
          }
          .centered-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .card-footer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap; /* Permet aux boutons de passer à la ligne sur les petits écrans */
          }
          .card-footer .button {
            flex: 1; /* Les boutons se répartissent également sur la largeur de l'écran */
            margin: 5px; /* Marge entre les boutons */
            max-width: 200px; /* Largeur maximale pour éviter le débordement sur les petits écrans */
          }
          
          /* Ajouter une classe pour gérer la largeur de la carte sur les petits écrans */
          @media screen and (max-width: 600px) {
            .custom-card {
              width: 100%;
              
            }
          }
        `}
      </style>
    <PageContainer
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
       {/* <LogoContainer>
        <Logo src={logoImage} alt="Logo" />
      </LogoContainer> */}

   
      <ImageContainer>
        <GlobeImage src={GlobeImg} alt="Globe" />
        
      </ImageContainer>
      <StyledButton onClick={handleStartGame}    
        >
          Jouer
        </StyledButton>
       {/* <But /> */}
       {/*
      <Title variants={titleVariants}><br/>Jeu Special <br/>Fin<br/> d'annee </Title>
    */}
    </PageContainer>
    </div>
  );
};

export default HomePage;

